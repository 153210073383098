import React, { useState, useEffect } from 'react'
import { useLocation } from '@reach/router'
import Pagina from '../components/Pagina'
import { graphql, Link } from 'gatsby'
import CardBlog from '../components/blog/card/blogCard'
// import BannerLwart from '../components/BannerLwart'
import { GatsbyImage } from 'gatsby-plugin-image'
// import Img from 'gatsby-image'

const Blog = ({ data }) => {
  const posts = data.allPosts.nodes.filter(post => post.title !== 'Expansion: Lwart will become the second largest re-refinery in the world!')

  console.log('data', data)
  console.log(data.allEditorias)

  const categories = [{ name: 'Todos', slug: '/blog', cor: '#007bff' }, ...data.allEditorias.nodes.map((ed) => { return { name: ed.nome, slug: ed.slug, cor: ed.cor } })]
  console.log('categorias:', categories)

  const [selectedCategory, setSelectedCategory] = useState('Todos')

  const location = useLocation()
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search)
    const categoryFromUrl = urlParams.get('category')
    if (categoryFromUrl) {
      setSelectedCategory(categoryFromUrl)
    }
  }, [location])

  const filteredPosts = selectedCategory === 'Todos'
    ? posts
    : posts.filter(post => post.category && post.category.includes(selectedCategory))

  return (
    <>
      <Pagina pagina={data.pagina} />
      {/* <BannerLwart
        desktop={data.paralax.childImageSharp.gatsbyImageData}
        mobile={data.paralaxMobile.childImageSharp.gatsbyImageData}
        // titulo='Já conhece o nosso blog?'
        // texto="É um espaço criado para compartilhar tudo o que temos feito e falar sobre assuntos relacionados a sustentabilidade, ecoeficiência e economia circular."
        className="bannerImage"
      /> */}
      <GatsbyImage
        image={data.paralax.childImageSharp.gatsbyImageData}
        className='d-md-block d-none'
      />
      <GatsbyImage
        image={data.paralaxMobile.childImageSharp.gatsbyImageData}
        className='d-md-none d-block'
      />

      <div className="container my-4">
        <div className="d-flex justify-content-center flex-wrap">
          {categories.map(category => (
            <Link
              key={category.slug}
              className={`btn mx-2 mb-2 ${selectedCategory === category.name ? '' : 'btn-outline-primary'}`}
              to={category.name === 'Todos' ? category.slug : `/blog/editoria/${category.slug}`}
              style={{
                backgroundColor: selectedCategory === category.name ? category.cor || '#00467d' : 'transparent',
                borderColor: category.cor || '#00467d',
                color: selectedCategory === category.name ? '#fff' : category.cor || '#00467d'
              }}
              onClick={() => setSelectedCategory(category.name)}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = category.cor || '#00467d'
                e.target.style.color = '#fff'
              }}
              onMouseLeave={(e) => {
                if (selectedCategory !== category.name) {
                  e.target.style.backgroundColor = 'transparent'
                  e.target.style.color = category.cor || '#00467d'
                }
              }}
            >
              {category.name}
            </Link>
          ))}
        </div>
      </div>

      <div className="container my-5">
        <div className="row">

          <div className="col-lg-6 col-md-12 mb-3">
            <CardBlog
              image={data.cardJogo.childImageSharp.gatsbyImageData} // URL ou objeto Gatsby Image
              alt="Descrição da imagem fixa"
              description="Descubra alguns marcos que fizeram parte da nossa jornada e monte a linha do tempo que conta a nossa história"
              title="Lwart 50 anos: monte a linha do tempo da nossa história"
              author={{ nome: 'Time Lwart', slug: 'time-lwart' }}
              readTime="1 min"
              date="2025-01-30"
              postLink="jogo-linha-do-tempo-lwart"
              editoria={{ nome: 'Todos', cor: '#007bff' }}
            />
          </div>

          {filteredPosts.length > 0 ? (
            filteredPosts.map((post) => (
              <div className="col-lg-6 col-md-12 mb-3" key={post.slug}>
                <CardBlog
                  image={post.cardImage.localFile.childImageSharp.gatsbyImageData}
                  alt={post.cardImage.description}
                  description={post.description.description}
                  title={post.title}
                  author={{ nome: post.author.nome, slug: post.author.slug }}
                  readTime={post.readTime}
                  date={post.date}
                  postLink={post.slug}
                  editoria={post.editoria}
                />
              </div>
            ))
          ) : (
            <p>Nenhum post encontrado para a categoria selecionada.</p>
          )}
        </div>
      </div>
    </>
  )
}

export default Blog

export const query = graphql`{
  pagina: contentfulPagina(path: {eq: "/blog/"}) {
    path
    metaTitle
    metaDescription
    pageTitle
    tags
    ogImage {
      localFile {
        publicURL
      }
    }
  }
  paralax: file(relativePath: {eq: "blog/Desktop.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  paralaxMobile: file(relativePath: {eq: "blog/Mobile.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 400, height: 350, layout: CONSTRAINED)
    }
  }
  cardJogo: file(relativePath: {eq: "blog/jogoBanner.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, width: 455, height: 300, layout: CONSTRAINED)
    }
  }
  allPosts: allContentfulBlogPost(
    filter: {node_locale: {eq: "pt-BR"}}
    sort: {fields: date, order: DESC}
  ) {
    nodes {
      node_locale
      title
      slug
      description {
        description
      }
      tags
      date(formatString: "DD/MM/YYYY")
      readTime
      cardImage {
        description
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 455, height: 300, layout: CONSTRAINED)
          }
        }
      }
      author {
        nome
        slug
        admin
      }
      editoria {
        cor
        nome
      }
    }
  }
    allEditorias: allContentfulEditorias(
    filter: {node_locale: {eq: "pt-BR"}}
  ) {
    nodes {
      node_locale
      nome
      slug
      cor
    }
  }
}
`

// export default injectIntl(NotFoundPage)
